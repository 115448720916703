.datatable-doc-demo {
    .p-paginator {
        .p-paginator-current {
            margin-left: auto;
        }
    }

    .p-progressbar {
        height: .5rem;
        background-color: #D8DADC;

        .p-progressbar-value {
            background-color: #607D8B;
        }
    }

    .table-header {
        display: flex;
        justify-content: space-between;
    }

    .p-datepicker {
        min-width: 25rem;

        td {
            font-weight: 400;
        }
    }

    .p-datatable.p-datatable-customers {
        .p-datatable-header {
            padding: 1rem;
            text-align: left;
            font-size: 1.5rem;
        }

        .p-paginator {
            padding: 1rem;
        }

        .p-datatable-thead > tr > th {
            text-align: left;
        }

        .p-datatable-tbody > tr > td {
            cursor: auto;
        }

        .p-dropdown-label:not(.p-placeholder) {
            text-transform: uppercase;
        }
    }

    /* Responsive */
    .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
        display: none;
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.p-datatable-customers {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    display: none !important;
                }

                .p-datatable-tbody > tr {
                    border-bottom: 1px solid var(--layer-2);

                    > td {
                        text-align: left;
                        display: block;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: .4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -.4rem 1rem -.4rem -.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: .5rem;
                        }
                    }
                }
            }
        }
    }
}

.datatable-responsive-demo {
    .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        display: none;
    }

    @media screen and (max-width: 40em) {
        .p-datatable {
            &.p-datatable-responsive-demo {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    display: none !important;
                }

                .p-datatable-tbody > tr > td {
                    text-align: left;
                    display: block;
                    width: 100%;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: .4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -.4em 1em -.4em -.4rem;
                        font-weight: bold;
                    }

                    &:last-child {
                        border-bottom: 1px solid var(--surface-d);
                    }
                }
            }
        }
    }
}

.datatable-rowexpansion-demo {
    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }

    .orders-subtable {
        padding: 1rem;
    }
}

.datatable-templating-demo {
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }
}

.datatable-filter-demo {
    .p-paginator {
        .p-paginator-current {
            margin-left: auto;
        }
    }

    .p-progressbar {
        height: .5rem;
        background-color: #D8DADC;

        .p-progressbar-value {
            background-color: #607D8B;
        }
    }

    .table-header {
        display: flex;
        justify-content: space-between;
    }

    .p-datepicker {
        min-width: 25rem;

        td {
            font-weight: 400;
        }
    }

    .p-datatable.p-datatable-customers {
        .p-datatable-header {
            padding: 1rem;
            text-align: left;
            font-size: 1.5rem;
        }

        .p-paginator {
            padding: 1rem;
        }

        .p-datatable-thead > tr > th {
            text-align: left;
        }

        .p-datatable-tbody > tr > td {
            cursor: auto;
        }

        .p-dropdown-label:not(.p-placeholder) {
            text-transform: uppercase;
        }
    }

    /* Responsive */
    .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
        display: none;
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.p-datatable-customers {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    display: none !important;
                }

                .p-datatable-tbody > tr {
                    border-bottom: 1px solid var(--layer-2);

                    > td {
                        text-align: left;
                        display: block;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: .4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -.4rem 1rem -.4rem -.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: .5rem;
                        }
                    }
                }
            }
        }
    }
}

.datatable-scroll-demo {
    .loading-text {
        display: block;
        background-color: #f1f1f1;
        min-height: 19px;
        animation: pulse 1s infinite ease-in-out;
        text-indent: -99999px;
        overflow: hidden;
    }
}

.datatable-editing-demo {
    .editable-cells-table td.p-cell-editing {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.datatable-crud-demo {
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.datatable-style-demo {
    .outofstock {
        font-weight: 700;
        color: #FF5252;
        text-decoration: line-through;
    }

    .lowstock {
        font-weight: 700;
        color: #FFA726;
    }

    .instock {
        font-weight: 700;
        color: #66BB6A;
    }

    .row-accessories {
        background-color: rgba(0,0,0,.15) !important;

    }
}

.datatable-rowgroup-demo {
    .p-rowgroup-footer td {
        font-weight: 700;
    }

    .p-rowgroup-header {
        span {
            font-weight: 700;
        }

        .p-row-toggler {
            vertical-align: middle;
            margin-right: .25rem;
        }
    }
}
